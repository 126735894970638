import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
const Healthdemo = props => {
  return (
    <>
      <section className="healthbestprice" id="healthbestprice">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-6  col-md-12 img-sect text-md-center text-lg-right">
              <img src={props.demoimage} alt="best-price3" />
              <br />

              <img
                src={props.demosubtitleimage}
                className="incred-price"
                alt="best-price1"
              />

              <div className="price-btn">
                <Link to="/contact#schedule">
                  <button className="btn btn-lg btn-price-btn">
                    Let's discuss!
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-lg-6  col-md-12  best-price-human text-md-center text-lg-left pt-md-5 ">
              <img src={props.Humanimage} alt="best-price" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Healthdemo
