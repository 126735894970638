import React from "react"
import { Link } from "gatsby"
const Healthsafetydetails = props => {
  return (
    <>
      <section className="health-crse-portfolio" id="health-crse-portfolio">
        <div className="container">
          <div className="row info">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 col-md-12 mob-geo text-lg-center ">
              <div className="title-geo">
                <h3>{props.healthsectiontitle}</h3>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
          <div className="row pb-lg-4">
            <div className="col-lg-4 col-md-12 d-flex justify-content-center  ">
              <div className="right-line">
                <div className="card" style={{ maxWidth: "20rem" }}>
                  <img
                    className="card-img-top img-fluid"
                    src={props.healthimage1}
                    alt={props.healthimage1title}
                    style={{ margin: "0" }}
                  />
                  <div className="card-body first-crs">
                    <h5 className="card-title">{props.healthimage1title}</h5>
                    <p className="card-text">{props.healthdesc1}</p>
                    <Link
                      to="/contact#schedule"
                      className="btn btn-primary first-crs-btn"
                    >
                      Get a Demo
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 d-flex justify-content-center  mt-5 mt-lg-0 pt-3 pt-lg-0 border-top border-dark">
              <div className="card line-right" style={{ maxWidth: "20rem" }}>
                <img
                  className="card-img-top img-fluid"
                  src={props.healthimage2}
                  alt={props.healthimage2title}
                  style={{ margin: "0" }}
                />
                <div className="card-body second-crs">
                  <h5 className="card-title">{props.healthimage2title}</h5>
                  <p className="card-text">{props.healthdesc2}</p>
                  <Link
                    to="/contact#schedule"
                    className="btn btn-primary second-crs-btn"
                  >
                    Get a Demo
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 d-flex justify-content-center  mt-5 mt-lg-0 pt-3 pt-lg-0 border-top border-dark ">
              <div className="card " style={{ maxWidth: "20rem" }}>
                <img
                  className="card-img-top img-fluid"
                  src={props.healthimage3}
                  alt={props.healthimage3title}
                  style={{ margin: "0" }}
                />
                <div className="card-body third-crs">
                  <h5 className="card-title">{props.healthimage3title}</h5>
                  <p className="card-text">{props.healthdesc3}</p>
                  <Link
                    to="/contact#schedule"
                    className="btn btn-primary third-crs-btn"
                  >
                    Get a Demo
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-12 d-flex justify-content-center  mt-5 mt-lg-0 pt-3 pt-lg-0 border-top border-dark">
              <div className="card " style={{ maxWidth: "20rem" }}>
                <img
                  className="card-img-top img-fluid"
                  src={props.healthimage4}
                  alt={props.healthimage4title}
                  style={{ margin: "0" }}
                />
                <div className="card-body fourth-crs">
                  <h5 className="card-title">{props.healthimage4title}</h5>
                  <p className="card-text">{props.healthdesc4}</p>
                  <Link
                    to="/contact#schedule"
                    className="btn btn-primary fourth-crs-btn"
                  >
                    Get a Demo
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex justify-content-center mt-5 mt-lg-0 pt-3 pt-lg-0 border-top border-dark">
              <div className="card line-right " style={{ maxWidth: "20rem" }}>
                <img
                  className="card-img-top img-fluid"
                  src={props.healthimage5}
                  alt={props.healthimage5title}
                  style={{ margin: "0" }}
                />
                <div className="card-body fifth-crs">
                  <h5 className="card-title">{props.healthimage5title}</h5>
                  <p className="card-text">{props.healthdesc5}</p>
                  <Link
                    to="/contact#schedule"
                    className="btn btn-primary fifth-crs-btn"
                  >
                    Get a Demo
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 d-flex justify-content-center mt-5 mt-lg-0 pt-3 pt-lg-0 border-top border-dark">
              <div className="card line-right " style={{ maxWidth: "20rem" }}>
                <img
                  className="card-img-top img-fluid"
                  src={props.healthimage6}
                  alt={props.healthimage6title}
                  style={{ margin: "0" }}
                />
                <div className="card-body sixth-crs">
                  <h5 className="card-title">{props.healthimage6title}</h5>
                  <p className="card-text">{props.healthdesc6}</p>
                  <Link
                    to="/contact#schedule"
                    className="btn btn-primary sixth-crs-btn"
                  >
                    Get a Demo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Healthsafetydetails
