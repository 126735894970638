import React from "react"
import { Link } from "gatsby"
import Cathero from "../components/Category-hero"

import Logoimg from "../components/Clients"
import Layout from "../components/layout"
import Advantage from "../components/Succeed-advantage"
import Healthsafetydetails from "../components/Health-safety-portfolio"
import Crsehighlight from "../components/Course-highlights"
import Quote from "../components/Testimonials"
import Healthdemo from "../components/Health-demo"
import HealthbottomDemo from "../components/Health-bottom-demo"

const Healthsafety = ({ data }) => {
  let safetydata = data.wordpressPage

  //Course Portfolio

  let healthsectiontitle = safetydata.acf.course_portfolio_title
  let healthimage1 =
    safetydata.acf.crseImage1.localFile.childImageSharp.fluid.originalImg
  let healthimage1title = safetydata.acf.crseTitle
  let healthdesc1 = safetydata.acf.wordpress_1st_description

  let healthimage2 =
    safetydata.acf.crseImage2.localFile.childImageSharp.fluid.originalImg
  let healthimage2title = safetydata.acf.title2
  let healthdesc2 = safetydata.acf.wordpress_2nd_description

  let healthimage3 =
    safetydata.acf.crseImage3.localFile.childImageSharp.fluid.originalImg
  let healthimage3title = safetydata.acf.title3
  let healthdesc3 = safetydata.acf.wordpress_3rd_description

  let healthimage4 =
    safetydata.acf.crseImage4.localFile.childImageSharp.fluid.originalImg
  let healthimage4title = safetydata.acf.title4
  let healthdesc4 = safetydata.acf.wordpress_4th_description

  let healthimage5 =
    safetydata.acf.crseImage5.localFile.childImageSharp.fluid.originalImg
  let healthimage5title = safetydata.acf.title5
  let healthdesc5 = safetydata.acf.wordpress_5th_description_copy

  let healthimage6 =
    safetydata.acf.crseImage6.localFile.childImageSharp.fluid.originalImg
  let healthimage6title = safetydata.acf.title6
  let healthdesc6 = safetydata.acf.wordpress_6th_description

  return (
    <>
      <Layout>
        {/* Hero Section */}
        <section className="financial-hero securityhead" id="financial-hero">
          <Cathero
            data={safetydata.acf.title}
            img={
              safetydata.acf.image.localFile.childImageSharp.fluid.originalImg
            }
            tabimage={
              safetydata.acf.tablet_image.localFile.childImageSharp.fluid
                .originalImg
            }
            mobimage={
              safetydata.acf.mobile_image.localFile.childImageSharp.fluid
                .originalImg
            }
            desc={safetydata.acf.description}
            subtitle={safetydata.acf.subtitle}
          />
        </section>

        <section className="clients" id="clients">
          <Logoimg
            clientsrow={data.allWordpressAcfClientsLogos}
            title={data.wordpressPage.acf.clients_title}
          />
          <hr className="client-below" />
        </section>

        {/* Advantage Section */}
        <Advantage
          title={safetydata.acf.adv_title}
          img1={
            safetydata.acf.imageone.localFile.childImageSharp.fluid.originalImg
          }
          img2={
            safetydata.acf.imagesecond.localFile.childImageSharp.fluid
              .originalImg
          }
          img3={
            safetydata.acf.imagethree.localFile.childImageSharp.fluid
              .originalImg
          }
          imgdesc1={safetydata.acf.image1desc}
          imgdesc2={safetydata.acf.image2}
          imgdesc3={safetydata.acf.image3}
        />
        <hr className="client-below" />

        {/* Courses Portfolio */}
        <Healthsafetydetails
          healthsectiontitle={healthsectiontitle}
          healthimage1={healthimage1}
          healthimage1title={healthimage1title}
          healthdesc1={healthdesc1}
          healthimage2={healthimage2}
          healthimage2title={healthimage2title}
          healthdesc2={healthdesc2}
          healthimage3={healthimage3}
          healthimage3title={healthimage3title}
          healthdesc3={healthdesc3}
          healthimage4={healthimage4}
          healthimage4title={healthimage4title}
          healthdesc4={healthdesc4}
          healthimage5={healthimage5}
          healthimage5title={healthimage5title}
          healthdesc5={healthdesc5}
          healthimage6={healthimage6}
          healthimage6title={healthimage6title}
          healthdesc6={healthdesc6}
        />

        <Healthdemo
          Humanimage={
            safetydata.acf.human_image.localFile.childImageSharp.fluid
              .originalImg
          }
          demoimage={
            safetydata.acf.demo_title_image.localFile.childImageSharp.fluid
              .originalImg
          }
          demosubtitleimage={
            safetydata.acf.demo_subtitle_image.localFile.childImageSharp.fluid
              .originalImg
          }
        />

        <section className="harass-highlights" id="harass-highlights">
          <Crsehighlight
            page={"Harassment"}
            section={"highlights"}
            h3title={"title-highlights"}
            harassh3title={"title-harass-highlights"}
            harassection={"harass-highlights"}
            img1={
              safetydata.acf.crse_highlights1.localFile.childImageSharp.fluid
                .originalImg
            }
            crsetitle1={safetydata.acf.crse_highlights1_title_and_description}
            img2={
              safetydata.acf.crse_highlights2.localFile.childImageSharp.fluid
                .originalImg
            }
            crsetitle2={safetydata.acf.crse_highlights2_title_and_description}
            img3={
              safetydata.acf.crse_highlights3.localFile.childImageSharp.fluid
                .originalImg
            }
            crsetitle3={safetydata.acf.crse_highlights3_title_and_description}
            img4={
              safetydata.acf.crse_highlights4.localFile.childImageSharp.fluid
                .originalImg
            }
            crsetitle4={safetydata.acf.crse_highlights4_title_and_description}
            img5={
              safetydata.acf.crse_highlights5.localFile.childImageSharp.fluid
                .originalImg
            }
            crsetitle5={safetydata.acf.crse_highlights5_title_and_description}
            img6={
              safetydata.acf.crse_highlights6.localFile.childImageSharp.fluid
                .originalImg
            }
            crsetitle6={safetydata.acf.crse_highlights6_title_and_description}
            img7={
              safetydata.acf.crse_highlights7.localFile.childImageSharp.fluid
                .originalImg
            }
            crsetitle7={safetydata.acf.crse_highlights7_title_and_description}
            img8={
              safetydata.acf.crse_highlights8.localFile.childImageSharp.fluid
                .originalImg
            }
            crsetitle8={safetydata.acf.crse_highlights8_title_and_description}
            img9={
              safetydata.acf.crse_highlights9.localFile.childImageSharp.fluid
                .originalImg
            }
            crsetitle9={safetydata.acf.crse_highlights9_title_and_description}
          />
        </section>

        <hr style={{ margin: 0, borderTop: "2px solid #a8a8a8" }} />

        <section className="testimonials" id="testimonials">
          <Quote testimonialtitle={"What our clients have to say"} />
        </section>
        <section className="health-bottom-demo" id="health-bottom-demo">
          <HealthbottomDemo
            bottomdemoimg={
              safetydata.acf.bottom.localFile.childImageSharp.fluid.originalImg
            }
          />
        </section>
        <section className="course-domains" id="course-domains">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6 title">
                <h5>Our Other Course Domains</h5>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row py-3">
              <div className="col-lg-4 col-md-12 crs-card">
                <div className="card" style={{ width: "18rem" }}>
                  <Link to="/Infosec-data-protection">
                    <img
                      className="card-img-top"
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1608039154/succeedlearn/bttjvhuyluw9jtpaed5k.png"
                      alt="infosec"
                      style={{ margin: 0 }}
                    />
                    <div className="card-body card-titledesc1 cardsec">
                      <p className="card-text">Security Awareness</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 crs-card">
                <Link to="/financial-compliance/">
                  <div className="card" style={{ width: "18rem" }}>
                    <img
                      className="card-img-top"
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606990669/succeedlearn/h7vqfalfwttrez5nnwmq.png"
                      alt="infosec"
                      style={{ margin: 0 }}
                    />
                    <div className="card-body card-titledesc2">
                      <p className="card-text">Financial Crime Prevention</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-12 crs-card">
                <Link to="/Harassment-discrimination-courses">
                  <div className="card" style={{ width: "18rem" }}>
                    <img
                      className="card-img-top"
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606990669/succeedlearn/ufwaunp0cguzwslnslvn.png"
                      alt="infosec"
                      style={{ margin: 0 }}
                    />
                    <div className="card-body card-titledesc1">
                      <p className="card-text">
                        Discrimination & Harassment Prevention
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Healthsafety

export const healthhero = graphql`
  {
    allWordpressAcfSucceedlearnLogo {
      edges {
        node {
          acf {
            logo_image_ {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressAcfSucceedlearnApple {
      edges {
        node {
          acf {
            logo_image_ {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }

    wordpressPage(title: { eq: "Health and Safety" }) {
      acf {
        clients_title
        title
        description
        image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        tablet_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        mobile_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        adv_title
        image1desc
        imageone {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
          }
          alt_text
        }
        image2
        imagesecond {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
          }
          alt_text
        }

        image3
        imagethree {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
          }
          alt_text
        }

        adv_title
        image1desc
        imageone {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }
        image2
        imagesecond {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        image3
        imagethree {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }
        course_portfolio_title
        wordpress_1st_description
        crseTitle
        crseImage1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        title2
        wordpress_2nd_description
        crseImage2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        title3
        wordpress_3rd_description
        crseImage3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        title4
        wordpress_4th_description
        crseImage4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        title5
        wordpress_5th_description_copy
        crseImage5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        title6
        wordpress_6th_description
        crseImage6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        human_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        demo_title_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        demo_subtitle_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        bottom {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights1_title_and_description
        crse_highlights1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights2_title_and_description
        crse_highlights2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights3_title_and_description
        crse_highlights3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights4_title_and_description
        crse_highlights4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights5_title_and_description
        crse_highlights5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights6_title_and_description
        crse_highlights6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights7_title_and_description
        crse_highlights7 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights8_title_and_description
        crse_highlights8 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights9_title_and_description
        crse_highlights9 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        bottom {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
      }
    }

    allWordpressAcfClientsLogos {
      edges {
        node {
          acf {
            client_logo_images {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
