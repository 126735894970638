import React from "react"
import { Link } from "gatsby"
const HealthbottomDemo = props => {
  return (
    <>
      <div className="container-fluid health-bg-grey ">
        <div className="row bg-color">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-lg-6 col-md-12 schedule-health-demo">
                <h5>
                  See how Succeed can <br />
                  <span className="h5-below">
                    enable Health & Safety Compliance
                    <br /> Awareness for your organization
                  </span>
                </h5>
                <div className="health-demobtn">
                  <Link to="/contact#schedule">
                    <button className="health-demo-btn">Schedule a Demo</button>
                  </Link>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <img
                  src={props.bottomdemoimg}
                  alt="demo-works"
                  style={{ margin: "0" }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </>
  )
}

export default HealthbottomDemo
